<template>
  <div class="file">
    <div style="display: flex;align-items: baseline">
      <label>{{ agreementName }}：</label>
      <el-button
        style="float: left"
        @click="submitFile"
        v-show="agreementData.fileList.length !== 0"
      >
        {{$t('UploadFile')}}
      </el-button>
    </div>
    <el-upload
      class="upload-demo"
      :file-list="agreementData.fileList"
      :limit="1"
      drag
      :accept="accept"
      action="https://test.attorneyathena.com/saas-api/athena-consumer/AppUploadDO/imgUpload"
      :on-exceed="moreThanOne"
      :before-upload="beforeFileUpload"
      :on-remove="onRemove"
      :on-success="onSuccess"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">{{$t('DragUpload')}}<em>{{$t('SelectFile2')}}</em></div>
      <div
        class="el-upload__tip"
        slot="tip"
        style="color: black"
        v-if="updateTime"
      >
        {{$t('FinalTime') + updateTime}}
      </div>
    </el-upload>
    <div style="margin-top: 30px">
      <a
        href="javascript:;"
        v-show="agreementData.initUrl!==''"
        @click="downloadFile"
      >
        {{$t('HasFile')}}
      </a>
    </div>
  </div>
</template>

<script>
import { setCookie, getCookie } from "@/assets/js/tool";
import util from "@/assets/js/util";

export default {
  props: {
    agreementName: {
      //协议名称
      type: String,
      default: "",
    },
    agreementData: {
      //协议文件数据
      type: Object,
      default: () => ({
        fileList: [],
        initUrl: "",
        updateTime: "",
      }),
    },
    accept: {
      //文件类型
      type: String,
      default: ".pdf, .PDF",
    },
    updateTime: {
      //更新时间
      type: String,
      default: "",
    },
    paramType: {
      //协议类型
      type: String,
      default: "",
    },
  },

  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      right: Number(data.contentPermission),
    };
  },

  mounted() {},

  methods: {
    //文件数量超出一个
    moreThanOne() {
      this.$message.error(this.$t("OneFileOnly"));
    },

    //上传文件之前的钩子
    beforeFileUpload(file) {
      if (this.right < 2) {
        this.$message.error(this.$t("NoRight"));
        return false;
      } else {
        if (this.agreementData.initUrl) {
          this.$message.warning({
            message: this.$t("UploadFileTips"),
            duration: 4000,
          });
        }

        const fileSuffix = file.name.substring(file.name.lastIndexOf("."));
        const suffixList = this.accept.split(",");
        if (suffixList.indexOf(fileSuffix) === -1) {
          this.$message.error(this.$t("FileOnly"));
          return false;
        }
      }
    },

    //上传文件成功 获取文件URL
    onSuccess(res, file, fileList) {
      let param = this.paramType;
      let fileUrl = file.response.data[0];
      let fileObj = { uid: 1, url: fileUrl };
      this.$emit("onSuccess", { res, file, param });
    },

    //删除文件
    onRemove(file, fileList) {
      let param = this.paramType;
      this.$emit("onRemove", { file, fileList, param });
    },

    //手动上传
    submitFile() {
      this.$emit("submitFile", this.paramType);
    },

    //点击下载
    downloadFile() {
      let fileName = this.agreementName + ".pdf";
      util.download(this.agreementData.initUrl, fileName);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 20px;
  margin-bottom: 20px;
  display: block;
}

.file {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
</style>