<!-- 主页管理 -->
<template>
  <div>
    <!--上部框-->
    <div class="main1">
      <div class="logos">
        <!--商会logo-->
        <!--<div class="chamberlogo">
          <label style="margin-right: 20px">{{$t('Chamber')}}Logo：</label>
            &lt;!&ndash;<i class="el-icon-edit"  @click="dialogChangePicVisible = true"></i>&ndash;&gt;
            <el-upload
                    class="avatar-uploader"
                    action="https://test.attorneyathena.com/saas-api/athena-consumer/AppUploadDO/imgUpload"
                    ref="logo"
                    :show-file-list="false"
                    :on-change="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                <img v-if="logo" :src="logo" class="avatar">
                <i v-else class="el-icon-plus; avatar-uploader-icon"></i>
            </el-upload>
            <el-button style="display: flex" @click="submitLogo" v-show="logo != initLogoUrl">确认上传</el-button>
            <div style="height: 40px; display: block;width: 100%;content: '';"></div>
        </div>-->
        <!--商会头像-->
        <div class="chamberAvatar">
          <label>{{$t('Chamber')}} {{$t('Avatar')}}：</label>
          <el-upload
            class="avatar-uploader"
            action="https://test.attorneyathena.com/saas-api/athena-consumer/AppUploadDO/imgUpload"
            ref="avatar"
            :accept="avatarAccept"
            :show-file-list="false"
            :on-change="handleAvatarSuccessAvatar"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="avatar"
              :src="avatar"
              class="avatar"
            >
            <i
              v-else
              class="el-icon-plus; avatar-uploader-icon"
            ></i>
          </el-upload>
          <el-button
            style="display: flex"
            @click="submitAvatar"
            v-show="avatar !== initImgUrl"
          >{{$t('ConfirmUpload')}}</el-button>
          <div style="height: 40px; display: block;width: 100%;content: '';"></div>
        </div>
      </div>
      <!--商会名称-->
      <div>
        <div class="chamberName">
          <span style="margin-left: 100px; width: auto">{{$t('Chamber')}} {{$t('Name')}}:</span>
          <div class="nameEdit">
            <span>{{chamberName}}</span>
            <i
              class="el-icon-edit"
              @click="syncName(chamberName,'chamberName')"
            ></i>
          </div>
        </div>
        <!--系统名称-->
        <div class="chamberName">
          <span style="margin-left: 100px; width: auto">{{$t('System')}} {{$t('Name')}}:</span>
          <div class="nameEdit">
            <span>{{sysName}}</span>
            <i
              class="el-icon-edit"
              @click="syncName(sysName,'sysName')"
            ></i>
          </div>
        </div>
        <!--经商涉及国家-->
        <div class="involve-country">
          <div class="pcInfo_mainpage">
            <span>{{ $t('InvolveCountry') }}：</span>
            <i
              class="el-icon-circle-plus-outline"
              @click="editCountry"
            ></i>
          </div>
          <!--          <div class="bizType" v-for="con in choseCountry" :key="con.id">
            <span>{{con.name}}</span>
          </div>-->
          <div class="countries-container">
            <div
              class="bizType"
              v-for="con in choseCountry"
              :key="con.id"
            >
              <span>{{con.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <!--轮播图-->
      <!--<div class="carousel">
          <div style="display: flex;align-items: center;margin-bottom: 20px">
              <label style="width: 200px;display: block;margin-bottom: 10px;float: left;">{{$t('Carousel')}}：{{length+'/5'}}</label>
              <el-button style="float: left" @click="submitLombok" v-show="lombokList.length >= 1 && lombokList != initLombok">确认上传</el-button>
          </div>
        <el-upload
                action="https://test.attorneyathena.com/saas-api/athena-consumer/AppUploadDO/imgUpload"
                list-type="picture-card"
                :limit="5"
                :on-change="getLombokUrl"
                :file-list="lombokList"
                :class="{hide:hideUploadBtn}"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </div>-->
    </div>

    <!--下部框-->
    <div class="mian-view">
      <div class="main2">
        <!-- 法务互通 -->
        <div class="title-view">{{ $t("Fsystem") }}</div>
        <!--用户协议-->
        <Agreement
          :agreementName="useAgreementName"
          :agreementData="lawUseAgreement"
          :updateTime="lawUseAgreement.updateTime"
          paramType="lawUseAgreement"
          @onSuccess="onSuccess"
          @onRemove="handleRemove"
          @submitFile="submitFile"
        >
        </Agreement>

        <!--个人隐私-->
        <Agreement
          :agreementName="privacyPolicyName"
          :agreementData="lawPrivacyPolicy"
          :updateTime="lawPrivacyPolicy.updateTime"
          paramType="lawPrivacyPolicy"
          @onSuccess="onSuccess"
          @onRemove="handleRemove"
          @submitFile="submitFile"
        >
        </Agreement>

        <!--关于我们-->
        <Agreement
          :agreementName="aboutUsName"
          :agreementData="lawAboutUs"
          :updateTime="lawAboutUs.updateTime"
          paramType="lawAboutUs"
          @onSuccess="onSuccess"
          @onRemove="handleRemove"
          @submitFile="submitFile"
        >
        </Agreement>
      </div>

      <div class="main2">
        <!-- 商务互通 -->
        <div class="title-view">{{ $t("Ssystem") }}</div>
        <!--用户协议-->
        <Agreement
          :agreementName="useAgreementName"
          :agreementData="useAgreement"
          :updateTime="useAgreement.updateTime"
          paramType="useAgreement"
          @onSuccess="onSuccess"
          @onRemove="handleRemove"
          @submitFile="submitFile"
        >
        </Agreement>

        <!--个人隐私-->
        <Agreement
          :agreementName="privacyPolicyName"
          :agreementData="privacyPolicy"
          :updateTime="privacyPolicy.updateTime"
          paramType="privacyPolicy"
          @onSuccess="onSuccess"
          @onRemove="handleRemove"
          @submitFile="submitFile"
        >
        </Agreement>

        <!--关于我们-->
        <Agreement
          :agreementName="aboutUsName"
          :agreementData="aboutUs"
          :updateTime="aboutUs.updateTime"
          paramType="aboutUs"
          @onSuccess="onSuccess"
          @onRemove="handleRemove"
          @submitFile="submitFile"
        >
        </Agreement>
      </div>
    </div>

    <!--编辑名称弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="editNameDialog"
      :show-close="false"
      center
      append-to-body
    >
      <el-form
        :model="dialogContentForm"
        class="mainPage_editDialog"
      >
        <el-form-item :label="dialogContentForm.label">
          <el-input
            v-model="dialogContentForm.dialogContent"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editNameDialog = false">{{$t('Cancle')}}</el-button>
        <el-button
          type="primary"
          @click="editName(dialogContentForm.changingOne)"
        >{{$t('Confirm')}}</el-button>
      </div>
    </el-dialog>

    <!--编辑经商国家弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('Edit')+' '+$t('InvolveCountry')"
      :visible.sync="editCountryVisible"
      :show-close="false"
      center
      append-to-body
    >
      <div class="selectedArea">
        <div
          class="bizType_dialog"
          v-for="con in dialogChoseCountry"
          :key="con.id"
          v-show="dialogChoseCountry.length !== 0"
        >
          <span @click="deleteCon(con)">{{ con.name }}</span>
        </div>
      </div>
      <hr>
      <div
        class="businessType"
        v-if="unChoseCountry&&unChoseCountry.length>0"
      >
        <div
          v-for="con in unChoseCountry"
          :key="con.name"
          @click="selectCon(con)"
          class="bizCard"
        >{{ con.name }}
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editCountryVisible = false">{{$t('Cancle')}}</el-button>
        <el-button
          type="primary"
          @click="editInvolveCon"
        >{{$t('Confirm')}}</el-button>
      </div>
      <!--<el-button style="margin-left: 90%">{{ $t('Confirm') }}</el-button>-->
    </el-dialog>
  </div>
</template>

<script>
//API
import api from "@/fetch/api";

//组件
import Agreement from "@/components/Agreement.vue";

export default {
  components: {
    Agreement,
  },
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      editCountryVisible: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      isEdit1: false,
      isEdit2: false,
      editNameDialog: false,
      dialogChangePicVisible: false,
      showCropper: false,
      chamberId: data.chamberId,
      adminId: data.id,
      hideUploadBtn: false,
      right:data.contentPermission,

      chamberName: "",
      sysName: "",

      uploadImgUrl: "",
      uploadLogoUrl: "",
      logo: "",
      avatar: "",
      avatarAccept: ".jpg, .jpeg, .png",
      initImgUrl: "",
      initLogoUrl: "",

      initLombok: [],
      lombokList: [],
      lombokURLList: [],

      /** 商务-用户协议 */
      updateTime: "",
      useAgreementName: this.$t("UserAgreement"),
      useAgreement: {
        id: "",
        fileList: [],
        uploadUrl: "",
        initUrl: "",
      },
      /** 商务-隐私政策 */
      privacyPolicyName: this.$t("Privacy"),
      privacyPolicy: {
        id: "",
        fileList: [],
        uploadUrl: "",
        initUrl: "",
      },
      /** 商务-关于我们 */
      aboutUsName: this.$t("AboutUs"),
      aboutUs: {
        id: "",
        fileList: [],
        uploadUrl: "",
        initUrl: "",
      },

      /** 法务-用户协议 */
      lawUpdateTime: "",
      lawUseAgreement: {
        id: "",
        fileList: [],
        uploadUrl: "",
        initUrl: "",
      },
      /** 法务-隐私政策 */
      lawPrivacyPolicy: {
        id: "",
        fileList: [],
        uploadUrl: "",
        initUrl: "",
      },
      /** 法务-关于我们 */
      lawAboutUs: {
        id: "",
        fileList: [],
        uploadUrl: "",
        initUrl: "",
      },

      bySystemIdList: [], //协议列表

      dialogContentForm: {
        changingOne: "",
        dialogContent: "",
        label: "",
      },

      editMainPage: {
        chamberId: data.chamberId,
        currentAdmin: data.id,
      },

      // 经商国家
      choseCountry: [], //经商涉及国家
      choseCountryId: [], //经商涉及国家id
      dialogChoseCountry: [], //弹窗中选中的国家
      dialogChoseCountryId: [], //弹窗中选中的国家id
      unChoseCountry: [], //弹窗中未选的国家
      unChoseCountryId: [], //弹窗中未选的国家id
      countryObjList: [], //国家列表
    };
  },
  computed: {
    length() {
      let length = this.lombokList.length;
      return length;
    },
  },
  mounted() {
    this.initMainPage();
    this.initAllCountries();
  },
  methods: {
    //初始化
    initMainPage() {
      api
        .getMainPage({
          chamberId: this.chamberId,
          currentAdmin: this.adminId,
        })
        .then(
          (res) => {
            this.queryListBySystemId(); //获取协议列表
            this.chamberName = res.data.name;
            this.sysName = res.data.systemName;
            this.logo = res.data.logo;
            this.initLogoUrl = res.data.logo;
            this.avatar = res.data.avatar;
            this.initImgUrl = res.data.avatar;
            this.choseCountry = res.data.involveCountryData; // 接口传过来的国家
            this.choseCountryId = res.data.involveCountryIds;

            // this.useAgreement.uploadUrl = res.data.useAgreement;
            // this.privacyPolicy.uploadUrl = res.data.privacyPolicy;
            // this.aboutUs.uploadUrl = res.data.aboutUs;
            // this.useAgreement.initUrl = res.data.useAgreement;
            // this.privacyPolicy.initUrl = res.data.privacyPolicy;
            // this.aboutUs.initUrl = res.data.aboutUs;

            this.lombokList = [];
            this.lombokURLList = [];
            let lombok = res.data.map;
            for (var i in lombok) {
              this.initLombok.push({ name: i, url: lombok[i] });
              this.lombokList.push({ name: i, url: lombok[i] });
              this.lombokURLList.push(lombok[i]);
            }
          },
          (error) => {
            // console.log("请求失败了", error.message);
            this.$message.error(this.$t("SysBusy"));
          }
        );
    },

    //获取协议列表
    queryListBySystemId() {
      return api
        .queryListBySystemId({ systemId: this.chamberId })
        .then((res) => {
          if (res.code && res.code === 200) {
            this.bySystemIdList = res.data;

            const dataMap = {
              "0-1": {
                target: this.useAgreement,
                prop: "id",
                urlProp: "uploadUrl",
                initUrlProp: "initUrl",
              },
              "0-2": {
                target: this.privacyPolicy,
                prop: "id",
                urlProp: "uploadUrl",
                initUrlProp: "initUrl",
              },
              "0-3": {
                target: this.aboutUs,
                prop: "id",
                urlProp: "uploadUrl",
                initUrlProp: "initUrl",
              },
              "1-1": {
                target: this.lawUseAgreement,
                prop: "id",
                urlProp: "uploadUrl",
                initUrlProp: "initUrl",
              },
              "1-2": {
                target: this.lawPrivacyPolicy,
                prop: "id",
                urlProp: "uploadUrl",
                initUrlProp: "initUrl",
              },
              "1-3": {
                target: this.lawAboutUs,
                prop: "id",
                urlProp: "uploadUrl",
                initUrlProp: "initUrl",
              },
            };

            res.data.forEach((e) => {
              const key = `${e.systemType}-${e.systemProtocolType}`;
              const { target, prop, urlProp, initUrlProp } = dataMap[key];
              target[prop] = e.id;
              target[urlProp] = e.systemProtocolUrl;
              target[initUrlProp] = e.systemProtocolUrl;
            });
          }
        });
    },

    //----------------------------------------------------------------上传头像----------------------------------------------------

    //商会头像  获取本地图片URL
    handleAvatarSuccessAvatar(file, fileList) {
      this.tempImages = fileList;
      // console.log("tempImages-->" + JSON.stringify(this.tempImages));
      if (this.tempImages[this.tempImages.length - 1].status === "success") {
        this.uploadImgUrl = "";
        this.uploadImgUrl =
          this.tempImages[this.tempImages.length - 1].response.data;
      }
      this.avatar = this.uploadImgUrl;
      //          console.log('list '+this.uploadImgUrl)
    },

    //商会LOGO  获取本地图片URL
    handleAvatarSuccess(file, fileList) {
      this.tempLogos = fileList;
      //          console.log("tempLogos-->"+JSON.stringify(this.tempLogos))
      if (this.tempLogos[this.tempLogos.length - 1].status === "success") {
        this.uploadLogoUrl = "";
        this.uploadLogoUrl =
          this.tempLogos[this.tempLogos.length - 1].response.data;
      }
      this.logo = this.uploadLogoUrl;
      //          console.log('list '+this.uploadLogoUrl)
    },

    //判断图片格式
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isPNG = file.type === "image/png";
      const isJPEG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG && !isJPEG) {
        this.$message.error(this.$t("ImgOnly"));
      }
      if (!isLt2M) {
        this.$message.error(this.$t("MBOnly"));
      }
      return (isJPG || isPNG || isJPEG) && isLt2M;
    },

    //手动上传头像
    submitAvatar() {
      // console.log("上传头像", this.uploadImgList);
      this.editMainPage.avatar = this.uploadImgUrl;
      api.editMainPage(this.editMainPage).then((res) => {
        if (res.code === 200) {
          delete this.editMainPage.avatar;
          this.initMainPage();
        } else {
          /*this.$message({
            showClose: true,
            message: "更改出错了，请稍后重试",
            type: "error",
          });*/
          this.$message.error(this.$t("SysBusy"));
        }
        //              console.log('编辑名称',res.msg)
      });
    },

    //手动上传Logo
    submitLogo() {
      // console.log("上传头像", this.uploadLogoUrl);
      this.editMainPage.logo = this.uploadLogoUrl;
      api.editMainPage(this.editMainPage).then((res) => {
        if (res.code === 200) {
          // console.log("成功了");
          delete this.editMainPage.logo;
          this.initMainPage();
        } else {
          /*this.$message({
            showClose: true,
            message: "更改出错了，请稍后重试",
            type: "error",
          });*/
          this.$message.error(this.$t("SysBusy"));
        }
        //              console.log('编辑名称',res.msg)
      });
    },

    //-----------------------------------------------------------------轮播图------------------------------------------------------

    //轮播图  移除图片
    /*handleRemove(file, fileList) {
          console.log('删除图片',fileList)
          this.lombokList = fileList
          this.lombokURLList = []
          this.lombokList.forEach(img=>{
              this.lombokURLList.push(img.url)
          })
          console.log('删除后上传',this.lombokURLList)
          this.hideUploadBtn = this.lombokList.length >= 5;
          this.hideUploadBtn = this.lombokURLList.length >= 5;
      },*/

    //预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //获取轮播图URL
    getLombokUrl(file, fileList) {
      this.tempLomboks = fileList;
      // console.log("tempLomboks-->" + JSON.stringify(this.tempLomboks));
      if (this.tempLomboks[this.tempLomboks.length - 1].status === "success") {
        this.lombokUrl = [];
        this.lombokUrl =
          this.tempLomboks[this.tempLomboks.length - 1].response.data;
        this.lombokList.push({ name: 1, url: this.lombokUrl });
        //              this.lombokList.push(this.lombokUrl)
        this.lombokURLList.push(this.lombokUrl);
      }
      this.hideUploadBtn = this.lombokList.length >= 5;
      this.hideUploadBtn = this.lombokURLList.length >= 5;
      // console.log("轮播图", this.lombokList);
    },

    //上传轮播图
    submitLombok() {
      // console.log("上传", this.lombokURLList);
      this.editMainPage.lombok = this.lombokURLList;
      api.editMainPage(this.editMainPage).then((res) => {
        if (res.code === 200) {
          // console.log("成功了");
          this.initMainPage();
        } else {
          /*this.$message({
            showClose: true,
            message: "更改出错了，请稍后重试",
            type: "error",
          });*/
          this.$message.error(this.$t("SysBusy"));
        }
        //              console.log('编辑名称',res.msg)
      });
    },

    //用户协议上传提醒
    beforeUploadWarning() {
      console.log("上传提醒");
    },

    //------------------------------------------------------------------编辑名称-------------------------------------------------------------

    //同步弹窗的名字
    syncName(name, tag) {
      // if(confirm(this.$t('alert'))){
      this.editNameDialog = true;
      // console.log("tag", tag, "/");
      this.dialogContentForm.dialogContent = name;
      this.dialogContentForm.changingOne = tag;
      this.dialogContentForm.label =
        tag === "chamberName"
          ? this.$t("Chamber") + this.$t("Name")
          : this.$t("System") + this.$t("Name");
      // }
    },

    //编辑名称
    editName(tag) {
      // console.log("editName", tag);
      if (!this.dialogContentForm.dialogContent.trim())
        return alert(this.$t("EmptyInput"));
      else {
        //              console.log('编辑内容',this.dialogContentForm.dialogContent)
        if (tag === "chamberName") {
          delete this.editMainPage.systemName;
          this.editMainPage.name = this.dialogContentForm.dialogContent;
        } else {
          delete this.editMainPage.name;
          this.editMainPage.systemName = this.dialogContentForm.dialogContent;
        }
        api.editMainPage(this.editMainPage).then((res) => {
          if (res.code === 200) {
            // console.log("成功了");
            this[tag] = this.dialogContentForm.dialogContent;
            this.editNameDialog = false;
            delete this.editMainPage.systemName;
            delete this.editMainPage.name;
            this.initMainPage();
          } else {
            /*this.$message({
              showClose: true,
              message: "更改出错了，请稍后重试",
              type: "error",
            });*/
            this.$message.error(this.$t("SysBusy"));
          }
          //              console.log('编辑名称',res.msg)
        });
        //              console.log('编辑完成',this.editMainPage)
      }
    },

    //----------------------------------------------------------------上传文件------------------------------------------------------------

    //获取文件URL
    onSuccess({ res, file, param }) {
      let fileUrl = file.response.data[0];
      let fileObj = { uid: file.uid, name: file.name, url: fileUrl };
      this[param].fileList.unshift(fileObj);
      this[param].uploadUrl = fileUrl;
    },

    //超出一个
    moreThanOne() {
      this.$message.error(this.$t("OneFileOnly"));
    },

    //删除文件
    handleRemove({ file, fileList, param }) {
      this[param].fileList = [];
      this[param].uploadUrl = "";
    },

    //手动上传
    submitFile(param) {
      this.editMainPage[param] = this[param].uploadUrl;
      this[param].initUrl = this[param].uploadUrl;
      api.editMainPage(this.editMainPage).then((res) => {
        if (res.code === 200) {
          this.getProtocolName(param);

          this.$nextTick(() => {
            delete this.editMainPage[param];
            this.initMainPage(); //初始化
          });
        } else {
          this.$message.error(this.$t("SysBusy"));
        }
      });
    },

    //获取协议名称
    getProtocolName(param) {
      let protocolMap = {
        useAgreement: {
          systemProtocolName: this.useAgreementName,
          systemProtocolType: 1,
          systemType: 0,
        },
        privacyPolicy: {
          systemProtocolName: this.privacyPolicyName,
          systemProtocolType: 2,
          systemType: 0,
        },
        aboutUs: {
          systemProtocolName: this.aboutUsName,
          systemProtocolType: 3,
          systemType: 0,
        },
        lawUseAgreement: {
          systemProtocolName: this.useAgreementName,
          systemProtocolType: 1,
          systemType: 1,
        },
        lawPrivacyPolicy: {
          systemProtocolName: this.privacyPolicyName,
          systemProtocolType: 2,
          systemType: 1,
        },
        lawAboutUs: {
          systemProtocolName: this.aboutUsName,
          systemProtocolType: 3,
          systemType: 1,
        },
      };

      let params = {
        id: this[param].id, //协议id，覆盖时传
        systemId: this.chamberId, //律所id/商会id
        systemProtocolName: "", //协议名称
        systemProtocolUrl: this[param].uploadUrl, //协议Url
        systemProtocolType: "", // 协议类型  1用户协议 2隐私条款 3关于我们
        systemType: "", // 0 商务互通协议 1 法务互通协议 2律所协议
      };

      if (protocolMap[param]) {
        params = { ...params, ...protocolMap[param] };
        this.addProtocol(params);
      }

      this[param].uploadUrl = "";
      this[param].fileList = [];
    },

    //添加协议
    addProtocol(params) {
      api.addProtocol(params).then((res) => {
        if (res.code === 200) {
        } else {
          this.$message.error(this.$t("SysBusy"));
        }
      });
    },

    // ------------------------------------------------经商国家-----------------------------------------
    //获取所有国家
    initAllCountries() {
      let name = "name";
      this.countryObjList = [];
      switch (this.lang) {
        case "zh-CN":
          name = "name";
          break;
        case "en-US":
          name = "enName";
          break;
        case "bahasa":
          name = "bahasaName";
          break;
      }
      api.getAllCountries({}).then((res) => {
        res.data.forEach((country) => {
          let countryObj = {};
          countryObj.id = country.id;
          countryObj.name = country[name];
          this.countryObjList.push(countryObj);
        });
      });
      // console.log('this.countryObjList ->',this.countryObjList)
    },

    //编辑经商国家弹窗
    editCountry() {
      if(this.right<2){
      this.$message.error(this.$t('NoRight'))
    }else{
        this.dialogChoseCountry = [];
        this.unChoseCountry = [];
        let countryObjList = JSON.parse(JSON.stringify(this.countryObjList));
  
        countryObjList.forEach((ele, index) => {
          if (!this.choseCountry || this.choseCountry.length <= 0 ) {
            this.choseCountry = [];
            this.editCountryVisible = true;
            this.choseCountry.forEach((el) => {
              if (ele.id === el.id) this.dialogChoseCountry.push(ele);
            });
          } else {
            this.choseCountry.forEach((el) => {
              if (ele.id === el.id) this.dialogChoseCountry.push(ele);
            });
          }
        });
        this.unChoseCountry = countryObjList.filter(
            (bItem) => !this.choseCountry.some((aItem) => aItem.id === bItem.id)
        );
        this.editCountryVisible = true;
      }
    },

    //取消选择国家
    deleteCon(e) {
      this.dialogChoseCountry.forEach((ele, i) => {
        if (ele.id === e.id) {
          this.dialogChoseCountry.splice(i, 1);
          this.dialogChoseCountryId.splice(i, 1);
        }
      })

      this.unChoseCountry.unshift(e);

    },

    //弹窗选择国家
    selectCon(e) {
      this.unChoseCountry.forEach((ele, i) => {
        if (ele.id === e.id) this.unChoseCountry.splice(i, 1);
      })

      this.dialogChoseCountry.push(e);
      this.dialogChoseCountryId = [];
      this.dialogChoseCountry.forEach(el => this.dialogChoseCountryId.push(el.id));
    },

    //更改经商涉及国家
    editInvolveCon() {
      this.editMainPage.involveCountryIds = this.dialogChoseCountryId;
      api.editMainPage(this.editMainPage).then((res) => {
        if (res.code === 200) {
          this.editCountryVisible = false;
          this.initMainPage();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main1 {
  width: 1600px;
  min-height: 400px;
  border: 1px solid black;
  background-color: #677d95;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 20px 10px 10px;
  .chamberName {
    font-size: 20px;
    display: flex;
    align-items: baseline;
    margin-bottom: 40px;
    & > span {
      margin-right: 20px;
    }
    .nameEdit {
      border: 1px solid black;
      height: 40px;
      padding: 5px;
      span {
        margin-right: 10px;
      }
      i {
        cursor: pointer;
      }
    }
  }
  input {
    border: 1px solid white;
    height: 40px;
  }
  .logos {
    //margin-left: 15px;
    margin: 30px 0 0 15px;
    align-self: flex-start;
    /*display: flex;*/
    /*flex-flow: row nowrap;*/
    /*justify-content: space-around;*/
    .chamberlogo {
      font-size: 20px;
      float: left;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
    .chamberAvatar {
      font-size: 20px;
      float: left;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: flex-start;
      label {
        margin-bottom: 20px;
      }
      i {
        cursor: pointer;
      }
    }
  }
  .carousel {
    font-size: 20px;
    ul {
      float: left;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.el-icon-plus {
  line-height: 178px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.hide {
  .el-upload--picture-card {
    display: none !important;
  }
}

.mian-view {
  width: 1600px;
  // height: 400px;
  border: 1px solid black;
  background-color: #677d95;
  padding-top: 50px;

  .title-view {
    position: absolute;
    top: -48px;
    left: 78px;
    font-size: 22px;
  }
}

.main2 {
  width: 1600px;
  height: 364px;
  // border: 1px solid black;
  // background-color: #677d95;
  margin-top: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: baseline;
  position: relative;
  // padding-top: 50px;
  label {
    font-size: 20px;
    margin-bottom: 20px;
    display: block;
  }
  .file {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
}
.mainPage_editDialog {
  min-height: 200px;
  display: flex;

  .el-form-item {
    margin: auto;
    display: flex;
    label {
      min-width: 120px;
      font-size: 20px;
      float: left;
    }
  }

  .el-form-item__content {
    font-size: 20px;
  }

  .el-input__inner {
    float: left;
    min-width: 500px;
    font-size: 20px;
  }
}

.selectedArea {
  width: 100%;
  max-height: 300px;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
  overflow: auto;

  .bizType_dialog {
    margin-left: 5px;
    line-height: 30px;
    cursor: pointer;

    & > span {
      //background-color: black;
      height: 50px;
      display: block;
      border-radius: 18px;
      background-color: #465d7c;
      color: white;
      font-size: 16px;
      float: left;
      padding: 8px;
      margin: 3px;
    }
  }
}
.businessType {
  max-height: 300px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow: auto;

  .bizCard {
    line-height: 30px;
    font-size: 16px;
    cursor: pointer;
  }
}

.involve-country {
  margin-bottom: 50px;
  //padding: 0 40px;
  margin-left: 100px;

  .countries-container {
    width: 600px;
    min-height: 200px;
    max-height: 390px;
    overflow: auto;
    .bizType {
      margin-left: 10px;
      float: left;
      height: 30px;
      vertical-align: middle;
      display: flex;
      cursor: pointer;
      margin-top: 10px;

      & > span {
        //background-color: black;
        color: white;
        font-size: 16px;
        padding: 5px;
      }
    }
  }
}

.pcInfo_mainpage {
  height: 40px;
  width: 100%;
  /*float: left;*/
  font-size: 20px;
  display: flex;
  align-items: center;

  i {
    cursor: pointer;
    font-size: 30px;
  }

  & > span {
    width: fit-content;
    height: 40px;
    line-height: 40px;
    display: block;
    float: left;
  }
}
</style>